
// Fonts
@import url(http://fonts.googleapis.com/css?family=Roboto:400,300,700|Open+Sans:700|Anton);

// Variables
@import "variables";

html{
	font-size:30% !important;
	@include sm{font-size:62.5% !important;}

}
//Custom Style
.btn-gray{
	background: $gray;
	background-color: $gray;
	color: $white;
	font-weight: 700;
	@include transition(all 0.5s);
	&:hover{
		background-color: $black-light;
		color: $yellow;
	}
}

.btn-yellow{
	@include button-bg($yellow, $white);	
}

.btn-green{
	@include button-bg($green, $white);	
}

.Ama{
	@include lg{
		margin-top: 201px;
		margin-bottom: 133px;
	}
}



.bg-yellow{
	@include bg-color($yellow, $white);
}

.bg-green{
	@include bg-color($green, $white);
}

.bg-blue{
	@include bg-color($blue-light, $white);
}

.green{
	color: $green;
}

.t1{
	font-family: anton;
	@include font-size(44);
	&.w{
		color: $white;
	}
}

.t3{
	@include font-size(30);
	margin-bottom: 15px;
	&.w{
		color: $white;
	}
}

.t4{
	@include font-size(20);
	margin-bottom: 15px;
	&.w{
		color: $white;
	}
}

.soc{
	text-align: left;
	margin-top: 15px;
	ul{
		margin-left: 0px !important;
	}
}


.slicknav_menu:before{
	float: left;
	height: 40px;
	background: url(../../images/logo.png)no-repeat;
	width: 110px;
	margin-left: 7px;
	margin-top: 0px;
	background-size: cover;
}

.headerLine{
	background: url('../../images/dotbg.png');
	background-repeat: repeat;
	#menuF, .container{
		position: relative;
		z-index: 2;
	}

	#menuF{
		@include transition(all 0.5s);
		&.fixed{
			position: fixed !important;
			z-index: 999 !important; 
		}
		height: 0px;
		@include md{
			height: auto;
		}
	}

	#portada-content{
		h1{
			@include font-size(48);
			color: $white;
			@include sm{
				@include font-size(45);
			};
			@include md{
				@include font-size(48);
			};
		}

		.col-text{

		}

		.col-img{
			text-align: center;
			@include md{
				text-align: left;
			}
			.img-addon{
				background-color: rgba(255, 255, 255, 0.56);
				width: 80%;
				margin-bottom: 20px;
				border-radius: 4px;
				@include md{
					margin-bottom: 0px;
					width: 100%;
					background: none;
				}
			}
		}
	}

	#img-addon{
		position: absolute;
		z-index: 1;
		bottom: 0;
		right: 0;
		left: 0;
		margin: auto;
		width: 80%;
		@include sm{
			margin: initial;
			width: initial;
			left: initial;
			width: 50%;
		} 

		@include md{
			width: 40%;
		}

		@include lg{right: 5%;} 
	}

	&.is-loading{
		#bg {
			opacity: 0;
		}
	}

	height: 400px;

	@include sm{
		height: 500px;
	}
	@include md{
		height: 550px;
	}

}

.pic2Ab, .pic1Ab{
	width: 150px;
	padding-bottom: 40px;
}

.line2{
	background: url('../../images/dotyellow.png');
	background-repeat: repeat;
}

.line4{
	background: url('../../images/dotyellow.png');
	background-repeat: repeat;
}

.line5{
	background: url('../../images/dotyellow.png');
	background-repeat: repeat;
}

.line7{
	padding-top: 150px;
	background: url('../../images/dotbg.png');
	background-repeat: repeat;
	.ftext {
		h3, p{
			color: $white;	
		}
	}
}

.line-last{
	background: url('../../images/dotbg.png');
	background-repeat: repeat;
	height: 100px;
	position: relative;
	width: 100%;
	overflow: hidden;
	&:before{
		content: "";
		position: absolute;
		z-index: 1;
		pointer-events: none;
		bottom: 0;
		left: 0;
		width: 100%;
		-webkit-background-size: cover;
		-moz-background-size: cover;
		-o-background-size: cover;
		background-size: cover;
		border-bottom: 150px solid $black-light;
		border-left: 3000px solid transparent;
	}
}


.lineBlack{
	background: $black-light;
	&:before{
		content: "";
		width: 0px;
		height: 0px;
	}

	.downLine{
		margin-top: 20px;
		margin-bottom: 20px;
	}
}

#news{
	p{
		text-align: justify;
	}
	ul{
		text-align: left;
		padding-left: 20px;
		li{
			color: $gray;
			@include font-size(15);
			list-style: initial;
			text-decoration: initial;
			display: list-item;
		}

	}
}


#form-contacto{
	input[type='text'], input[type='email'], textarea{
		margin-bottom: 10px;
	}


}
.info-contacto{
	padding-left: 0px;
	li{
		font-size: 15px;
		color:$white;
		list-style: none;
		i{
			color:$yellow;
			margin-right: 5px;
		}
		a{
			color: $white;
			i{
				color:$yellow;
			}
		}
	}
}


//Background


#bg {
	@include transition(pacity 2s ease-in-out);
	height: 100%;
	left: 0;
	opacity: 0.3;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 1;

	div {
		@include transition(opacity 3s ease, visibility 3s);
		background-size: cover;
		height: 100%;
		left: 0;
		opacity: 0;
		position: absolute;
		top: 0;
		visibility: hidden;
		width: 150%;

		&.visible{
			@include animation('bg 45s linear infinite');
			opacity: 1;
			visibility: visible;
			z-index: 1;

			@include xs{
				@include animation('bg 18s linear infinite');
			}

			@include md{
				@include animation('bg 29.25s linear infinite');
			}

		}
		&.top{
			z-index: 2;
		}

		&:only-child {
			@include animation-direction('alternate !important');
		}
	}
}



@include keyframes(bg)
{
	0% {
		@include transform(translateX(0));
	}

	100% {
		@include transform(translateX(-25%));
	}
}

