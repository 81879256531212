//Colors
$yellow: #dea428;

$blue: #33709b;
$blue-light: #269ec6;
$black: #000;
$white: #fff;
$gray: #2f2f2f;
$black-light: #101010;
$green: #92ae01;

//Media querys
@mixin xs {
  @media only screen and (min-width: 600px) {
    @content;
  }
}

@mixin sm {
  @media only screen and (min-width: 768px) {
    @content;
  }
}

@mixin md {
  @media only screen and (min-width: 992px) {
    @content;
  }
}

@mixin lg {
  @media only screen and (min-width: 1200px) {
    @content;
  }
}

//Uso: 
//@include xs {
    //width: 100%;
    //float: none;
    //margin: 0;
  //}
  //
//Mixin Background Color
@mixin bg-color($bg-color, $text-color) {
    background-color: $bg-color;
    color: $text-color;
  padding: 7px;
  line-height: 1.5;
}

//Mixin Font Size
@function calculateRem($size) {
  $remSize: $size / 16px;
  @return $remSize * 1rem;
}

@mixin font-size($sizeValue: 1.6) {
  font-size: $sizeValue + px;
  $rem: $sizeValue / 10;
  font-size: $rem + rem;
}

//Uso: @include font-size(14px)

//Mixin Transitions
@mixin transition($transition...) {
    -moz-transition:    $transition;
    -o-transition:      $transition;
    -webkit-transition: $transition;
    transition:         $transition;
}
@mixin transition-property($property...) {
    -moz-transition-property:    $property;
    -o-transition-property:      $property;
    -webkit-transition-property: $property;
    transition-property:         $property;
}
@mixin transition-duration($duration...) {
    -moz-transition-property:    $duration;
    -o-transition-property:      $duration;
    -webkit-transition-property: $duration;
    transition-property:         $duration;
}
@mixin transition-timing-function($timing...) {
    -moz-transition-timing-function:    $timing;
    -o-transition-timing-function:      $timing;
    -webkit-transition-timing-function: $timing;
    transition-timing-function:         $timing;
}
@mixin transition-delay($delay...) {
    -moz-transition-delay:    $delay;
    -o-transition-delay:      $delay;
    -webkit-transition-delay: $delay;
    transition-delay:         $delay;
}

//Uso: @include transition(background-color 1s .5s, color 2s);

//KEYFRAMES
@mixin keyframes($animation)
{
    @-webkit-keyframes #{$animation} {
        @content;
    }
    @-moz-keyframes #{$animation} {
        @content;
    }
    @-o-keyframes #{$animation} {
        @content;
    }
    @keyframes #{$animation} {
        @content;
    }
}

//TRANSFORM MIXINS
// generic transform
@mixin transform($transforms) {
     -moz-transform: $transforms;
       -o-transform: $transforms;
      -ms-transform: $transforms;
  -webkit-transform: $transforms;
          transform: $transforms;
}
// rotate
@mixin rotate ($deg) {
  @include transform(rotate(#{$deg}deg));
}
 
// scale
@mixin scale($scale) {
   @include transform(scale($scale));
} 
// translate
@mixin translate ($x, $y) {
   @include transform(translate($x, $y));
}
// skew
@mixin skew ($x, $y) {
   @include transform(skew(#{$x}deg, #{$y}deg));
}
//transform origin
@mixin transform-origin ($origin) {
    moz-transform-origin: $origin;
       -o-transform-origin: $origin;
      -ms-transform-origin: $origin;
  -webkit-transform-origin: $origin;
          transform-origin: $origin;
}

//Uso: @include skew(25,10);
  //@include transform-origin(top left);

//MIXIN ANIMATION FOR ANIMATION KEYFRAMES
@mixin animation($str) {
  -webkit-animation: #{$str};
  -moz-animation: #{$str};
  -ms-animation: #{$str};
  -o-animation: #{$str};
  animation: #{$str};      
}

@mixin animation-direction($str) {
  -webkit-animation-direction: #{$str};
  -moz-animation-direction: #{$str};
  -ms-animation-direction: #{$str};
  -o-animation-direction: #{$str};
  animation-direction: #{$str};      
}

//Uso: @include animation('slide-down 5s 3');


//BTN MIXIN
@mixin button-bg($bg, $color-text) {
  background: $bg;
  padding: 10px 15px;
  color: $color-text;
  &:hover {
    background:darken($bg,8%);
    transition: all 0.3s ease;
  }
  &:active {
    background:darken($bg,25%);
  } 
  &.btn-xl{
    @include font-size(25);
  }
}

//Uso: @include button-bg(#2ecc71);